export const endpointUrl = import.meta.env.VITE_PROPERTY_ADMIN_GRAPHQL ?? ''
export const propertyAPI = import.meta.env.VITE_PROPERTY_API || ''
export const bafUrl = import.meta.env.VITE_BAF_URL || ''
export const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN
export const SENTRY_ENVIRONMENT = import.meta.env.VITE_SENTRY_ENVIRONMENT ?? ''
export const environment = import.meta.env.VITE_ENV || ''
export const isInProduction = environment === 'production' || environment === 'release'
export const isInDevelopment = environment === 'development'
export const isInTest = environment === 'test'
export const isInConversion = environment === 'conversion'

const title: { [key: string]: string } = {
  development: 'DEV',
  test: 'TEST',
  conversion: 'CONV',
  stage: 'STAGE',
  release: 'RELEASE',
}
export const showEnv = title[environment] ?? ''

if (showEnv === title.test) {
  process.env.TZ = import.meta.env.VITE_TZ || 'UTC'
}

import { t } from 'i18next'
import { propertyAPI } from '../../config'
import { CountySettings, PostBasicCostParams } from '../../models/api'
import { countySettings } from '../mocks'
import { fetchFromApi } from './config'
import {
  AccountDetailReportParams,
  AppraisalRegionReportParams,
  BalanceDueNoticeParams,
  CurrentPaymentSummaryReportParams,
  DailyPaymentTotalsReportParams,
  DelinquentPaymentEntryPreviewReportParams,
  DepositTotalsReportParams,
  LeaseMasterPaymentEntryReceiptParams,
  PaymentContractReportParams,
  PaymentTotalsByPaymentTypeReportParams,
} from './interfaces'

export async function getCountySettings(): Promise<CountySettings> {
  // TODO: Replace this logic with a call to the BE to get county settings
  // https://mcatcama.visualstudio.com/Puma/_workitems/edit/ ?
  if (process.env.NODE_ENV !== 'test') {
    await new Promise((resolve) => setTimeout(resolve, 200))
  }

  return Promise.resolve(countySettings)
}

export async function postCountySettings(countySettingsPayload: CountySettings): Promise<CountySettings> {
  // TODO: Replace this logic with a call to the BE to post county settings
  // https://mcatcama.visualstudio.com/Puma/_workitems/edit/ ?
  if (countySettingsPayload.countyName === 'fake name') {
    return Promise.reject(new Error('Backend error!'))
  }

  if (process.env.NODE_ENV !== 'test') {
    await new Promise((resolve) => setTimeout(resolve, 200))
  }

  return Promise.resolve(countySettingsPayload)
}

export async function postBasicCost(params: PostBasicCostParams): Promise<PostBasicCostParams> {
  // TODO: Replace this logic with a call to the BE to get county settings
  // https://mcatcama.visualstudio.com/Puma/_workitems/edit/ ?
  if (process.env.NODE_ENV !== 'test') {
    await new Promise((resolve) => setTimeout(resolve, 200))
  }

  // return new Promise((resolve) => setTimeout(() => resolve(params), 500))
  return Promise.resolve(params)
}

export async function fetchBalanceDueNotice(token: string, params: BalanceDueNoticeParams): Promise<Blob> {
  const url = `${propertyAPI}/api/Report/BalanceDueReport`

  return await fetchFromApi(url, 'POST', token, t('unableToDownloadBalanceDueReport'), params)
}

export async function fetchAccountDetailReport(params: AccountDetailReportParams, token: string): Promise<Blob> {
  const url = `${propertyAPI}/api/Report/GetAccountDetailReport/${params.accountId}?taxYear=${params.taxYear}`

  return await fetchFromApi(url, 'GET', token, t('unableToDownloadAccountDetailReport'))
}

export async function fetchDailyPaymentTotalsReport(
  params: DailyPaymentTotalsReportParams,
  token: string,
): Promise<Blob> {
  let url = `${propertyAPI}/api/Report/GetDailyPaymentTotalsReport/${params.dateReceived}`

  if (params.ein) {
    url += `?ein=${params.ein}`
  }

  return await fetchFromApi(url, 'GET', token, t('unableToDownloadDailyPaymentTotalsReport'))
}

export async function fetchAccountListReport(taxYear: number, token: string): Promise<Blob> {
  const url = `${propertyAPI}/api/Report/GetAccountListForPrinterReport/${taxYear}`

  return await fetchFromApi(
    url,
    'GET',
    token,
    t('unableToDownloadItem', { item: t('accountListForPrinterReport') }),
  )
}

export async function fetchAccountValueChangePriorYearReport(taxYear: number, token: string): Promise<Blob> {
  const url = `${propertyAPI}/api/Report/GetAccountValueChangePriorYearToCurrYearReport/${taxYear}`

  return await fetchFromApi(
    url,
    'GET',
    token,
    t('unableToDownloadItem', { item: t('accountValueChangePriorYearReport') }),
    undefined,
    'text/plain',
  )
}

export async function fetchMissingAppraiserRegionReport(taxYear: number, token: string): Promise<Blob> {
  const url = `${propertyAPI}/api/Report/GetMissingAppraiserRegionReport/${taxYear}`

  return await fetchFromApi(
    url,
    'GET',
    token,
    t('unableToDownloadItem', { item: t('missingAppraiserRegionReport') }),
  )
}

export async function fetchTaxAreaListReport(taxYear: number, token: string): Promise<Blob> {
  const url = `${propertyAPI}/api/Report/GetTaxAreaListReport/${taxYear}`

  return await fetchFromApi(
    url,
    'GET',
    token,
    t('unableToDownloadItem', { item: t('taxAreaListReport') }),
    undefined,
    'text/plain',
  )
}

export async function fetchFilingStatusReport(taxYear: number, token: string): Promise<Blob> {
  const url = `${propertyAPI}/api/Report/GetFilingStatusReport/${taxYear}`

  return await fetchFromApi(url, 'GET', token, t('unableToDownloadFilingStatusReport'))
}

export async function fetchUnfiledStatusReport(taxYear: number, token: string): Promise<Blob> {
  const url = `${propertyAPI}/api/Report/GetStatusCsvFile/UnfiledAccountsStatus/${taxYear}`

  return await fetchFromApi(url, 'GET', token, t('unableToDownloadUnfiledStatusReport'), undefined, 'text/plain')
}

export async function fetchHowFiledStatusReport(taxYear: number, token: string): Promise<Blob> {
  const url = `${propertyAPI}/api/Report/GetStatusCsvFile/HowFiledStatus/${taxYear}`

  return await fetchFromApi(url, 'GET', token, t('unableToDownloadHowFiledStatusReport'), undefined, 'text/plain')
}

export async function fetchFiledWithBalanceStatusReport(taxYear: number, token: string): Promise<Blob> {
  const url = `${propertyAPI}/api/Report/GetStatusCsvFile/FiledWithBalanceStatus/${taxYear}`

  return await fetchFromApi(
    url,
    'GET',
    token,
    t('unableToDownloadFiledWithBalanceStatusReport'),
    undefined,
    'text/plain',
  )
}

export async function fetchAppraisalRegionReport(
  token: string,
  params: AppraisalRegionReportParams,
): Promise<Blob> {
  const url = `${propertyAPI}/api/Report/GetAccountStatusByAppraisalRegionReport`

  return await fetchFromApi(
    url,
    'POST',
    token,
    t('unableToDownloadFiledForAppraisalRegionReport'),
    { ...params, details: [] },
    'text/csv',
  )
}

export async function fetchPaymentContractReport(
  params: PaymentContractReportParams,
  token: string,
): Promise<Blob> {
  const url = `${propertyAPI}/api/Report/GetPaymentContractReport/${params.accountId}/${params.taxYear}/${params.paymentPlanType}`

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      authorization: `bearer ${token}`,
      Accept: 'application/pdf',
    },
  })

  if (!response.ok) {
    const data = await response.json()
    throw new Error(data?.title ?? t('unableToDownloadPaymentContractReport'))
  }

  return await response.blob()
}

export async function fetchCurrentPaymentSummaryReport(
  params: CurrentPaymentSummaryReportParams,
  token: string,
): Promise<Blob> {
  let url = `${propertyAPI}/api/Report/GetCurrentPaymentSummaryReport/${params.dateReceived}`

  if (params.ein) {
    url += `?ein=${params.ein}`
  }

  return await fetchFromApi(url, 'GET', token, t('unableToDownloadCurrentPaymentSummaryReport'))
}

export async function putFileInAzureStorage(url: string, file: File): Promise<Response> {
  const response = await fetch(url, {
    body: file,
    method: 'PUT',
    headers: {
      'x-ms-blob-type': 'BlockBlob',
    },
  })

  if (!response.ok) {
    throw new Error(response.statusText)
  }

  return response
}

export async function fetchCurrentYearPaymentEntryReceipt(paymentId: number, token: string): Promise<Blob> {
  const url = `${propertyAPI}/api/Report/GetCurrentYearPaymentEntryReceiptReport/${paymentId}`

  return await fetchFromApi(url, 'GET', token, t('unableToDownloadCurrentYearPaymentEntryReport'))
}

export async function fetchActiveAccountsReport(taxYear: number, token: string): Promise<Blob> {
  const url = `${propertyAPI}/api/Report/GetActiveAccountListReport/${taxYear}`

  return await fetchFromApi(url, 'GET', token, t('unableToDownloadActiveAccountsReport'), undefined, 'text/plain')
}

export async function fetchAccountCommentReport(accountId: number, token: string): Promise<Blob> {
  const url = `${propertyAPI}/api/Report/GetAccountCommentsReport/${accountId}`

  return await fetchFromApi(url, 'GET', token, t('unableToDownloadAccountCommentReport'), undefined, 'text/plain')
}

export async function fetchDelinquentPaymentEntryPreviewReport(
  params: DelinquentPaymentEntryPreviewReportParams,
  token: string,
): Promise<Blob> {
  const url = `${propertyAPI}/api/Report/GetDelinquentPaymentEntryPrintScreenReport`

  return await fetchFromApi(url, 'POST', token, t('unableToDownloadDelinquentPaymentEntryPreviewReport'), params)
}

export async function fetchDepositTotalsReport(params: DepositTotalsReportParams, token: string): Promise<Blob> {
  const url = `${propertyAPI}/api/Report/GetDepositTotalsByDayReport/${params.startDate}/${params.endDate}`

  return await fetchFromApi(url, 'GET', token, t('unableToDownloadItem', { item: t('depositTotalsReport') }))
}

export async function fetchDelinquentYearPaymentEntryReceipt(paymentId: number, token: string): Promise<Blob> {
  const url = `${propertyAPI}/api/Report/GetDelinquentTaxReceiptReport/${paymentId}`

  return await fetchFromApi(url, 'GET', token, t('unableToDownloadDelinquentYearPaymentEntryReport'))
}

export async function fetchDelinquentPaymentSummaryReport(accountId: number, token: string): Promise<Blob> {
  const url = `${propertyAPI}/api/Report/GetDelinquentPaymentSummaryReport/${accountId}`

  return await fetchFromApi(
    url,
    'GET',
    token,
    t('unableToDownloadItem', { item: t('delinquentPaymentSummaryReport') }),
  )
}

export async function fetchPaymentTotalsByPaymentTypeReport(
  params: PaymentTotalsByPaymentTypeReportParams,
  token: string,
): Promise<Blob> {
  const url = `${propertyAPI}/api/Report/GetPaymentTotalsByPaymentTypeReport/${params.startDate}/${params.endDate}`

  return await fetchFromApi(
    url,
    'GET',
    token,
    t('unableToDownloadItem', { item: t('paymentTotalsByPaymentTypeReport') }),
  )
}

export async function fetchOverpaymentReport(taxYear: number, token: string): Promise<Blob> {
  const url = `${propertyAPI}/api/Report/GetOverpaymentReport/${taxYear}`
  return await fetchFromApi(
    url,
    'GET',
    token,
    t('unableToDownloadItem', { item: t('overpaymentReport') }),
    undefined,
    'text/plain',
  )
}

export async function fetchLeaseMasterValueChangeReport(taxYear: number, token: string): Promise<Blob> {
  const url = `${propertyAPI}/api/Report/GetLeaseMasterValueChangeYearToYearReport/${taxYear}`
  return await fetchFromApi(
    url,
    'GET',
    token,
    t('unableToDownloadItem', { item: t('leaseMasterValueChangeReport') }),
    undefined,
    'text/plain',
  )
}

export async function fetchLeaseMasterPaymentEntryReceipt(
  payment: LeaseMasterPaymentEntryReceiptParams,
  token: string,
): Promise<Blob> {
  const url = `${propertyAPI}/api/Report/GetLeaseMasterCurrentYearPaymentEntryReceiptReport`
  return await fetchFromApi(url, 'POST', token, t('unableToDownloadItem', { item: t('paymentReceipt') }), payment)
}

export async function fetchAssociatedAccountsReport(taxYear: number, token: string): Promise<Blob> {
  const url = `${propertyAPI}/api/Report/GetAssociatedAccountsReport/${taxYear}`

  return await fetchFromApi(
    url,
    'GET',
    token,
    t('unableToDownloadAssociatedAccountsReport'),
    undefined,
    'text/csv',
  )
}

export async function fetchLeaseMasterAccountDetailReport(
  params: AccountDetailReportParams,
  token: string,
): Promise<Blob> {
  const url = `${propertyAPI}/api/Report/GetLeaseMasterAccountDetailReport/${params.accountId}/${params.taxYear}`
  return await fetchFromApi(url, 'GET', token, t('unableToDownloadAccountDetailReport'))
}

import {
  AccountType,
  BusinessAppraiserRegion,
  GetBusinessAccountQuery,
  CreateBusinessAccountMutation,
  GetCreateAccountInfoQuery,
  LoginMutation,
  SearchQuery,
  State,
  TaxArea,
  SaveBusinessAccountMutation,
  RegeneratePinMutation,
  ChangeBusinessAccountStatusMutation,
  BusinessAccount,
  Contact,
  GetCreateContactDataQuery,
  SitusAddress,
  Address,
  LeaseMasterBusinessAccount,
  UserInput,
  ChangeLeaseMasterAccountStatusMutation,
  MasterLoginMutation,
  TaxDistrictBusinessAccountRow,
  User as UserGraphQl,
  PostingPayment,
  CreateSubAccountTaxDistrictMutation,
  SubAccountTaxDistrict,
} from '../generated/graphql'
import { AuthInformation, Classification, CountySettings, User } from '../models/api'
import { mockNaicsCategories, mockNaicsCode } from '../tests/mocks/naics'

// TODO: Refactor this file

export const countySettings: CountySettings = {
  countyName: 'Utah County',
  supportUrl: 'https://www.utahcounty.gov/ContactUs/Index.asp',
  paymentUrl: 'https://www.utahcounty.gov/Payment/Index.asp',
}

export const classifications: Classification[] = [
  { id: 5, classification: 1, description: 'Desk' },
  { id: 1, classification: 2, description: 'Misc Short Life' },
  { id: 2, classification: 7, description: 'Copier' },
  { id: 3, classification: 3, description: 'Chair' },
  { id: 4, classification: 4, description: 'Filling Cabinet' },
  { id: 6, classification: 5, description: 'Lamp' },
  { id: 7, classification: 6, description: 'Computer' },
]

export const validLoginData: LoginMutation = {
  login: {
    token:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI3IiwiaXNBZG1pbiI6ZmFsc2UsImlzVGVsZWNvbVVzZXIiOnRydWUsInRlbGVjb21JZCI6IjIiLCJ0YXhZZWFyIjoiMiwwMjIiLCJleHAiOjE2NzI4NzE4ODgsImlzcyI6Imh0dHBzOi8vbG9jYWxob3N0OjQ0MzAwIiwiYXVkIjoiaHR0cHM6Ly9sb2NhbGhvc3Q6NDQzMDAifQ.oO3SdMy4GuhpxfGgB7FY6fXH5imeujnDD9cv6294V0U',
  },
}

export const validLoginWithNoToken: LoginMutation = {
  login: {
    title: 'Test Error',
    status: 500,
  },
}

export const unauthorizedData = {
  errors: [
    {
      message: 'Failed to login: Unauthorized',
      locations: [
        {
          line: 2,
          column: 3,
        },
      ],
      path: ['login'],
      extensions: {
        code: 'INTERNAL_SERVER_ERROR',
      },
    },
  ],
}

export const changePasswordData = {
  changePassword: {
    token:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI3IiwiaXNBZG1pbiI6ZmFsc2UsImlzVGVsZWNvbVVzZXIiOnRydWUsInRlbGVjb21JZCI6IjIiLCJ0YXhZZWFyIjoiMiwwMjIiLCJleHAiOjE2NzI4NzE4ODgsImlzcyI6Imh0dHBzOi8vbG9jYWxob3N0OjQ0MzAwIiwiYXVkIjoiaHR0cHM6Ly9sb2NhbGhvc3Q6NDQzMDAifQ.oO3SdMy4GuhpxfGgB7FY6fXH5imeujnDD9cv6294V0U',
  },
}

export const invalidChangePasswordData = {
  changePassword: {
    title: 'Incorrect password',
    status: 403,
  },
}

export const mockedAuthInformation: AuthInformation = {
  token: 'validJwtToken',
  userId: 1,
  jurisdictionId: 27,
  isMasterUser: false,
}

export const adminUser: User = {
  accountNumber: '1',
  businessName: 'Potato LLC',
  fullName: 'John Doe',
}

export const mockedMasterAuthInformation: AuthInformation = {
  token: 'validJwtToken',
  userId: 1,
  isMasterUser: true,
}

export const masterUser: User = {
  accountNumber: '1',
  businessName: 'Potato LLC',
  fullName: 'Master User Doe',
}

export const johnDoeUser: UserInput = {
  id: 1,
  email: 'jdoe@example.com',
  fullName: 'John Doe',
  username: 'jdoe',
  isActive: true,
  isVerified: true,
}

export const janeDoeUser: UserInput = {
  id: 2,
  email: 'janedoe@example.com',
  fullName: 'Jane Doe',
  username: 'janedoe',
  isActive: true,
  isVerified: true,
}

export const graphqlGetSessionContextError = [
  {
    message: 'Session Context not found for the provided Account Id',
    locations: [
      {
        line: 2,
        column: 5,
      },
    ],
    path: ['getSessionContext'],
    extensions: {
      code: 'INTERNAL_SERVER_ERROR',
    },
  },
]

export const mockedGetSimilarBusinessesResponseWithData = {
  similarBusinesses: [
    {
      accountNumber: 'P096',
      businessName: 'api Test Co',
      similarAddress: '7617 N 13th St, Washington',
    },
    {
      accountNumber: 'P097',
      businessName: 'Oscorp Corp',
      similarAddress: '7617 N 13th St, Washington',
    },
  ],
}

export const mockedGetSimilarBusinessesResponseWithNoData = {
  similarBusinesses: [],
}

export const mockedCreateAccountResponse: CreateBusinessAccountMutation = {
  createBusinessAccount: {
    id: '34',
    accountNumber: 'P044',
    pin: 'supersecretpin!',
    businessName: 'Potato LLC',
    description: null,
    sicCode: {
      id: '4',
      sicCode: '902',
      sicCodeDescription: 'GOLF COURSE',
      sicSiloCode: '7000',
      subSiloCode: '7700',
      isActive: true,
    },
    areaNumber: '025-0000',
    parcelNumber: '08354770040000',
    ein: null,
    registeredEntityName: '',
    isHomeOccupational: false,
    website: '',
    yearOpened: 0,
    primaryContact: {
      id: '2',
      cellphone: null,
      contactType: {
        id: '5',
        label: 'Other',
      },
      name: null,
      email: null,
      phone: null,
      phoneExtension: null,
      mailingAddress: {
        inCareOf: 'John Doe',
        addressLine1: '1544 Test Drive',
        addressLine2: 'Suite #3',
        city: 'Ogden',
        state: State.Ut,
        zipCode: '81531',
        country: {
          code: 'USA',
          name: 'United States of America',
        },
      },
    },
    parcelUseType: null,
    hasAssociatedAccounts: false,
    isActive: true,
    isAccountReadOnly: false,
    applyPenalty: true,
    lastModified: {
      username: '2 - businessowner@example.com',
      lastChangedDate: '2023-06-08T15:40:56',
    },
    accountType: {
      id: '1',
      label: 'Existing Business',
    },
    reconciliation: {
      reconciledBy: null,
    },
    filingStatus: {
      hasAttachment: false,
      hasBuildingPermit: false,
      hasExemption: false,
      dateReceived: null,
      filingYear: 2023,
      howFiled: null,
      howReceived: null,
    },
    audit: {
      auditYear: null,
    },
    businessAppraiserRegion: {
      id: '2',
      groupingValue1: 'Ogden',
      groupingValue2: 'OG',
      regionName: 'Region - 1',
    },
    situsAddress: {
      addressLine1: '1544 Test Drive',
      addressLine2: 'Suite #3',
      city: 'Ogden',
      state: State.Ut,
      zipCode: '81531',
    },
    accountSummary: {
      cost: 0,
      taxable: 0,
      taxRate: 0.007424,
      assessmentCharge: 0,
      directCharge: 0,
      penaltyCharge: 0,
      payments: 0,
      currentBalance: 0,
      delinquentBalance: 0,
      totalBalance: 0,
    },
  },
}

export const businessAppraiserRegionList: BusinessAppraiserRegion[] = [
  {
    regionName: 'Region - 1',
    columnsNames: {
      groupingLabel1: 'City Name',
      groupingLabel2: 'Code',
    },
    rows: [
      {
        id: '1',
        groupingValue1: 'Eden',
        groupingValue2: 'ED',
        regionName: 'Region - 1',
      },
      {
        id: '2',
        groupingValue1: 'Ogden',
        groupingValue2: 'OG',
        regionName: 'Region - 1',
      },
      {
        id: '3',
        groupingValue1: 'Hooper',
        groupingValue2: 'HO',
        regionName: 'Region - 1',
      },
    ],
  },
  {
    regionName: 'Region - 2',
    columnsNames: {
      groupingLabel1: 'City Name',
      groupingLabel2: 'Code',
    },
    rows: [
      {
        id: '4',
        groupingValue1: 'Farr West',
        groupingValue2: 'FW',
        regionName: 'Region - 2',
      },
      {
        id: '5',
        groupingValue1: 'Harrisville',
        groupingValue2: 'HA',
        regionName: 'Region - 2',
      },
    ],
  },
  {
    regionName: 'Region - 3',
    columnsNames: {
      groupingLabel1: 'City Name',
      groupingLabel2: 'Code',
    },
    rows: [
      {
        id: '6',
        groupingValue1: 'Ogden City',
        groupingValue2: 'OG',
        regionName: 'Region - 3',
      },
      {
        id: '7',
        groupingValue1: 'Salt Lake',
        groupingValue2: 'SC',
        regionName: 'Region - 3',
      },
    ],
  },
]

export const countries = [
  {
    code: 'USA',
    name: 'United States of America',
  },
  {
    code: 'DOM',
    name: 'Dominican Republic',
  },
  {
    code: 'HND',
    name: 'Honduras',
  },
  {
    code: 'ARG',
    name: 'Argentina',
  },
  {
    code: 'PAN',
    name: 'Panama',
  },
]

export const accountTypes: AccountType[] = [
  {
    id: '0',
    label: 'Unknown',
  },
  {
    id: '1',
    label: 'Existing Business',
  },
  {
    id: '2',
    label: 'New Business',
  },
  {
    id: '3',
    label: 'Rentals, 20 or less units',
  },
  {
    id: '4',
    label: 'Home Occupational, nontaxable',
  },
  {
    id: '6',
    label: 'Leasing Account',
  },
]

export const bankruptcyTypeList: AccountType[] = [
  {
    id: '11',
    label: 'Bankruptcy Ch. 11',
  },
  {
    id: '12',
    label: 'Bankruptcy Ch. 13',
  },
  {
    id: '10',
    label: 'Bankruptcy Ch. 7',
  },
]

export const taxAreaList: TaxArea[] = [
  {
    taxArea: '001',
    taxSubArea: ['0000'],
  },
  {
    taxArea: '002',
    taxSubArea: ['0000'],
  },
  {
    taxArea: '003',
    taxSubArea: ['0000', '0001', '0002'],
  },
  {
    taxArea: '004',
    taxSubArea: ['0000'],
  },
]
export const getCreateAccountInfoResponse: GetCreateAccountInfoQuery = {
  countries,
  accountTypes,
  taxAreaList,
  getBusinessAppraiserRegionList: businessAppraiserRegionList,
  sicCodeMasters: [
    {
      id: '4',
      sicCode: '902',
      sicCodeDescription: 'GOLF COURSE',
      sicSiloCode: '7000',
      subSiloCode: '7700',
      isActive: true,
    },
  ],
}

export const graphqlInternalServerError = [
  {
    message: 'Internal Server Error',
    locations: [
      {
        line: 8,
        column: 12,
      },
    ],
    extensions: {
      code: 'GRAPHQL_INTERNAL_SERVER_ERROR',
      exception: {
        stacktrace: ['GraphQLError: Dummy Stacktrace'],
      },
    },
  },
]

export const profile = {
  getProfile: {
    email: 'admin@example.com',
    username: 'admin',
  },
}

export const searchResult: SearchQuery = {
  search: [
    {
      email: 'jdoe@example.com',
      username: 'jdoe',
      id: 1,
      fullName: 'John Doe',
      isActive: true,
      isVerified: true,
      roles: [
        { id: 1, name: 'Test Role 1' },
        { id: 2, name: 'Test Role 2' },
      ],
    },
    {
      email: 'janedoe@example.com',
      username: 'janedoe',
      id: 2,
      fullName: 'Jane Doe',
      isActive: false,
      isVerified: true,
      roles: [{ id: 3, name: 'Test Role 3' }],
    },
    {
      email: 'admin@example.com',
      username: 'admin',
      id: 3,
      fullName: 'Admin Example',
      isActive: true,
      isVerified: true,
      roles: [],
    },
  ],
}

export const existingUsernameError = [
  {
    message: 'Username already exists',
  },
]

export const tableUsers = [
  {
    email: 'admin1@example.com',
    username: 'admin1',
    id: 1,
    fullName: 'Admin1 Example',
    isActive: true,
    isVerified: true,
  },
  {
    email: 'admin2@example.com',
    username: 'admin2',
    id: 2,
    fullName: 'Admin2 Example',
    isActive: true,
    isVerified: true,
  },
  {
    email: 'admin3@example.com',
    username: 'admin3',
    id: 3,
    fullName: 'Admin3 Example',
    isActive: true,
    isVerified: true,
  },
  {
    email: 'admin4@example.com',
    username: 'admin4',
    id: 4,
    fullName: 'Admin4 Example',
    isActive: true,
    isVerified: true,
  },
  {
    email: 'admin5@example.com',
    username: 'admin5',
    id: 5,
    fullName: 'Admin5 Example',
    isActive: true,
    isVerified: true,
  },
  {
    email: 'admin6@example.com',
    username: 'newPageUser',
    id: 6,
    fullName: 'New Page User',
    isActive: true,
    isVerified: true,
  },
  {
    email: 'admin7@example.com',
    username: 'newPageUser',
    id: 7,
    fullName: 'New Page User',
    isActive: true,
    isVerified: true,
  },
  {
    email: 'admin8@example.com',
    username: 'newPageUser',
    id: 8,
    fullName: 'New Page User',
    isActive: true,
    isVerified: true,
  },
  {
    email: 'admin9@example.com',
    username: 'newPageUser',
    id: 9,
    fullName: 'New Page User',
    isActive: true,
    isVerified: true,
  },
  {
    email: 'admin10@example.com',
    username: 'newPageUser',
    id: 10,
    fullName: 'New Page User',
    isActive: true,
    isVerified: true,
  },
  {
    email: 'newPageUser@example.com',
    username: 'newPageUser',
    id: 11,
    fullName: 'New Page User',
    isActive: true,
    isVerified: true,
  },
]

export const SearchBusinessAccountMock = {
  totalRecordCount: 2,
  searchBusinessAccount: [
    {
      taxYear: 2022,
      accountNbr: '9',
      accountId: 1,
      isActive: true,
      businessName: 'Sams Club',
      address: {
        addressLine1: 'Eden state',
        addressLine2: 'AddressLine 2 test',
        city: 'Washington',
        zipCode: '11102',
      },
      contactId: 20,
      taxDistrict: '200',
      isReconciled: false,
      appraisalRegion: {
        id: '2',
        label: 'Region - 1',
      },
      howReceived: {
        id: 'null',
        label: null,
      },
      balance: 0,
      recordType: 'I',
      status: null,
    },
    {
      taxYear: 2022,
      accountNbr: '001',
      accountId: 2,
      isActive: true,
      businessName: 'Tech Solutions',
      address: {
        addressLine1: '1 Elm Street',
        addressLine2: 'Suite A',
        city: 'Seattle',
        zipCode: '98101',
      },
      contactId: 101,
      taxDistrict: '201',
      isReconciled: true,
      appraisalRegion: {
        id: '1',
        label: 'North Region',
      },
      howReceived: {
        id: 'email',
        label: 'E-mail',
      },
      balance: 100,
      recordType: 'A',
      status: 'Active',
    },
    {
      taxYear: 2023,
      accountNbr: '002',
      accountId: 3,
      isActive: false,
      businessName: 'Green Gardens',
      address: {
        addressLine1: '22 Oak Avenue',
        addressLine2: 'Floor 2',
        city: 'Portland',
        zipCode: '97201',
      },
      contactId: 102,
      taxDistrict: '202',
      isReconciled: false,
      appraisalRegion: {
        id: '2',
        label: 'South Region',
      },
      howReceived: {
        id: 'mail',
        label: 'Mail',
      },
      balance: 250,
      recordType: 'B',
      status: 'Inactive',
    },
    {
      taxYear: 2023,
      accountNbr: '002',
      accountId: 4,
      isActive: false,
      businessName: 'Green Gardens',
      address: {
        addressLine1: '22 Oak Avenue',
        addressLine2: 'Floor 2',
        city: 'Portland',
        zipCode: '97201',
      },
      contactId: 102,
      taxDistrict: '202',
      isReconciled: false,
      appraisalRegion: {
        id: '2',
        label: 'South Region',
      },
      howReceived: {
        id: 'mail',
        label: 'Mail',
      },
      balance: 250,
      recordType: 'B',
      status: 'Inactive',
    },
    {
      taxYear: 2023,
      accountNbr: '002',
      accountId: 5,
      isActive: false,
      businessName: 'Green Gardens',
      address: {
        addressLine1: '22 Oak Avenue',
        addressLine2: 'Floor 2',
        city: 'Portland',
        zipCode: '97201',
      },
      contactId: 102,
      taxDistrict: '202',
      isReconciled: false,
      appraisalRegion: {
        id: '2',
        label: 'South Region',
      },
      howReceived: {
        id: 'mail',
        label: 'Mail',
      },
      balance: 250,
      recordType: 'B',
      status: 'Inactive',
    },
    {
      taxYear: 2023,
      accountNbr: '002',
      accountId: 12,
      isActive: false,
      businessName: 'Green Gardens',
      address: {
        addressLine1: '22 Oak Avenue',
        addressLine2: 'Floor 2',
        city: 'Portland',
        zipCode: '97201',
      },
      contactId: 102,
      taxDistrict: '202',
      isReconciled: false,
      appraisalRegion: {
        id: '2',
        label: 'South Region',
      },
      howReceived: {
        id: 'mail',
        label: 'Mail',
      },
      balance: 250,
      recordType: 'B',
      status: 'Inactive',
    },
    {
      taxYear: 2023,
      accountNbr: '002',
      accountId: 6,
      isActive: false,
      businessName: 'Green Gardens',
      address: {
        addressLine1: '22 Oak Avenue',
        addressLine2: 'Floor 2',
        city: 'Portland',
        zipCode: '97201',
      },
      contactId: 102,
      taxDistrict: '202',
      isReconciled: false,
      appraisalRegion: {
        id: '2',
        label: 'South Region',
      },
      howReceived: {
        id: 'mail',
        label: 'Mail',
      },
      balance: 250,
      recordType: 'B',
      status: 'Inactive',
    },
  ],
}

export const contactTypes = [
  {
    id: '1',
    label: 'Primary',
  },
  {
    id: '2',
    label: 'Owner',
  },
  {
    id: '3',
    label: 'Accountant',
  },
  {
    id: '4',
    label: 'Preparer',
  },
  {
    id: '5',
    label: 'Other',
  },
]

export const entityTypes = [
  {
    id: '1',
    label: 'LLC',
  },
  {
    id: '2',
    label: 'PLLC',
  },
  {
    id: '3',
    label: 'Corporation',
  },
  {
    id: '4',
    label: 'Partnership',
  },
  {
    id: '5',
    label: 'Sole Proprietorship',
  },
  {
    id: '6',
    label: 'Unknown',
  },
]

export const howFiledList = [
  {
    id: '1',
    label: 'FiledWithPayment',
  },
  {
    id: '2',
    label: 'FiledOnly',
  },
  {
    id: '3',
    label: 'PaymentOnly',
  },
  {
    id: '4',
    label: 'None',
  },
  {
    id: '5',
    label: 'Unknown',
  },
]

export const howReceivedList = [
  {
    id: '1',
    label: 'FiledWithPayment',
  },
  {
    id: '2',
    label: 'FiledOnly',
  },
  {
    id: '3',
    label: 'PaymentOnly',
  },
  {
    id: '4',
    label: 'None',
  },
]

const industrialParkList = [
  {
    id: '1',
    code: 'BDO',
    label: 'RE-Business Depot Ogden',
  },
  {
    id: '2',
    code: 'WIP',
    label: 'Weber Industrial Park',
  },
  {
    id: '3',
    code: 'OIP',
    label: 'Ogden Industrial Park',
  },
  {
    id: '4',
    code: 'HAFB',
    label: 'Hill Air Force Base',
  },
]

const parcelUseTypeList = [
  {
    id: '1',
    label: 'Residential',
  },
  {
    id: '2',
    label: 'Commercial',
  },
  {
    id: '3',
    label: 'Multiple Housing',
  },
  {
    id: '4',
    label: 'Land Only',
  },
]

export const sicCodeMasters = [
  {
    id: '4',
    isActive: true,
    sicCode: '902',
    sicCodeDescription: 'GOLF COURSE',
    sicSiloCode: '7000',
    subSiloCode: '7700',
  },
  {
    id: '5',
    isActive: true,
    sicCode: '903',
    sicCodeDescription: 'SKI RESORT',
    sicSiloCode: '7000',
    subSiloCode: '7700',
  },
  {
    id: '6',
    isActive: true,
    sicCode: '904',
    sicCodeDescription: 'THEATER',
    sicSiloCode: '7000',
    subSiloCode: '7700',
  },
  {
    id: '5711',
    sicCode: '5870',
    sicCodeDescription: 'Sandwich Shop/Beverage/Ice Cream Shop',
    sicSiloCode: '4000',
    subSiloCode: '4250',
    isActive: true,
  },
]

export const businessAccount = {
  id: '35',
  accountNumber: 'P01',
  pin: 'supersecretpin!',
  businessName: 'Rocky Mountain Cycle Plaza - RMCP API Test',
  description: 'Residential Remodeling',
  sicCode: {
    id: '4',
    sicCode: '902',
    sicCodeDescription: 'GOLF COURSE',
    sicSiloCode: '7000',
    subSiloCode: '7700',
    isActive: true,
  },
  naicsCategory: {
    id: 'MINING',
    category: 'MINING',
  },
  naicsCode: {
    id: '212200',
    category: 'MINING',
    subCategory: 'METAL ORE MINING',
  },
  areaNumber: '002-0000',
  parcelNumber: '225522',
  ein: '12-3456789',
  registeredEntityName: 'Rocky Mountain Cycle Plaza LLC',
  website: 'https://www.rockymountain.com/',
  yearOpened: 1998,
  isHomeOccupational: false,
  hasAssociatedAccounts: false,
  isActive: true,
  isAccountReadOnly: false,
  applyPenalty: true,
  primaryContact: {
    contactType: {
      id: '1',
      label: 'Primary',
    },
    id: '597',
    cellphone: '(719)921-2683',
    name: 'Rocky Mountain Cycle Plaza',
    phone: '(719)495-3489',
    phoneExtension: '8116',
    email: 'rmcp@gmail.com',
    mailingAddress: {
      inCareOf: 'Rocky Mountain Cycle Plaza',
      addressLine1: '1234 NewNew Flat Platte Ave',
      addressLine2: 'Room 54',
      city: 'Ogden',
      state: 'UT',
      zipCode: '81530',
      country: {
        code: 'USA',
        name: 'United States of America',
      },
    },
  },
  parcelUseType: {
    id: '2',
    label: 'Commercial',
  },
  accountType: {
    id: '1',
    label: 'Existing Business',
  },
  entityType: {
    id: '1',
    label: 'LLC',
  },
  industrialPark: {
    id: '1',
    code: 'BDO',
    label: 'RE-Business Depot Ogden',
  },
  lastModified: {
    username: '2 - businessowner@example.com',
    lastChangedDate: '2023-06-08T15:40:56',
  },
  reconciliation: {
    reconciledBy: null,
    reconciledId: null,
  },
  filingStatus: {
    hasAttachment: false,
    hasBuildingPermit: false,
    hasExemption: false,
    dateReceived: '2023-05-29T15:40:54',
    filingYear: 2023,
    howFiled: {
      id: '2',
      label: 'FiledOnly',
    },
    howReceived: {
      id: '3',
      label: 'PaymentOnly',
    },
  },
  audit: {
    auditYear: 2022,
  },
  businessAppraiserRegion: {
    id: '2',
    groupingValue1: 'Ogden',
    groupingValue2: 'OG',
    regionName: 'Region - 1',
  },
  situsAddress: {
    addressLine1: '1234 NewNew SuperSitus Ave',
    addressLine2: 'Suite 3',
    city: 'Ogden',
    state: State.Ut,
    zipCode: '81530',
  },
  accountSummary: {
    cost: 0,
    taxable: 0,
    taxRate: 0.007424,
    assessmentCharge: 0,
    directCharge: 0,
    penaltyCharge: 0,
    payments: 0,
    currentBalance: 0,
    delinquentBalance: 0,
    totalBalance: 0,
    interest: 0,
    totalBalanceWithDelinquent: 0,
  },
} as const

export const getBusinessAccountResponse: GetBusinessAccountQuery = {
  getBusinessAccount: businessAccount,
  accountTypes,
  contactTypes,
  countries,
  entityTypes,
  howFiledList,
  howReceivedList,
  industrialParkList,
  parcelUseTypeList,
  sicCodeMasters,
  taxAreaList,
  getBusinessAppraiserRegionList: businessAppraiserRegionList,
  naicsCategories: mockNaicsCategories,
  naicsCodes: mockNaicsCode,
  currentTaxYear: 2023,
}

export const getBusinessAccountResponseWithEmptyFields: GetBusinessAccountQuery = {
  getBusinessAccount: {
    id: '35',
    accountNumber: 'P01',
    pin: 'supersecretpin!',
    businessName: 'Rocky Mountain Cycle Plaza - RMCP API Test',
    description: null,
    sicCode: null,
    naicsCategory: null,
    naicsCode: null,
    areaNumber: '',
    parcelNumber: null,
    ein: null,
    registeredEntityName: null,
    website: null,
    yearOpened: null,
    isHomeOccupational: false,
    hasAssociatedAccounts: false,
    isActive: true,
    isAccountReadOnly: false,
    applyPenalty: true,
    primaryContact: {
      contactType: {
        id: '1',
        label: 'Primary',
      },
      id: '597',
      cellphone: '(719)921-2683',
      name: null,
      phone: '(719)495-3489',
      phoneExtension: '8116',
      email: 'rmcp@gmail.com',
      mailingAddress: {
        inCareOf: 'Rocky Mountain Cycle Plaza',
        addressLine1: '1234 NewNew Flat Platte Ave',
        addressLine2: 'Room 54',
        city: 'Ogden',
        state: 'UT',
        zipCode: '81530',
        country: {
          code: 'USA',
          name: 'United States of America',
        },
      },
    },
    parcelUseType: null,
    accountType: {
      id: '1',
      label: 'Existing Business',
    },
    entityType: null,
    industrialPark: null,
    lastModified: {
      username: null,
      lastChangedDate: null,
    },
    reconciliation: {
      reconciledBy: null,
      reconciledDate: null,
    },
    filingStatus: {
      hasAttachment: false,
      hasBuildingPermit: false,
      hasExemption: false,
      dateReceived: null,
      filingYear: null,
      howFiled: null,
      howReceived: null,
    },
    audit: {
      auditYear: null,
    },
    businessAppraiserRegion: {
      id: '2',
      groupingValue1: 'Ogden',
      groupingValue2: 'OG',
      regionName: 'Region - 1',
    },
    situsAddress: {
      addressLine1: '175 W 900 S',
      addressLine2: null,
      city: 'St. George',
      state: 'UT',
      zipCode: '84770',
    },
    accountSummary: {
      cost: null,
      taxable: null,
      taxRate: null,
      assessmentCharge: null,
      directCharge: null,
      penaltyCharge: null,
      payments: null,
      currentBalance: null,
      delinquentBalance: null,
      totalBalance: null,
    },
    hasCurrentPaymentPlan: true,
    hasDelinquentPaymentPlan: false,
  },
  accountTypes,
  contactTypes,
  countries,
  entityTypes,
  howFiledList,
  howReceivedList,
  industrialParkList,
  parcelUseTypeList,
  sicCodeMasters,
  taxAreaList,
  getBusinessAppraiserRegionList: businessAppraiserRegionList,
  naicsCategories: mockNaicsCategories,
  naicsCodes: mockNaicsCode,
  currentTaxYear: 2023,
}

export const getBusinessAccountResponseSuspended: GetBusinessAccountQuery = {
  getBusinessAccount: {
    ...businessAccount,
    filingStatus: { ...businessAccount.filingStatus, howFiled: { id: '4', label: 'Suspended' } },
  },
  accountTypes,
  contactTypes,
  countries,
  entityTypes,
  howFiledList,
  howReceivedList,
  industrialParkList,
  parcelUseTypeList,
  sicCodeMasters,
  taxAreaList,
  getBusinessAppraiserRegionList: businessAppraiserRegionList,
  naicsCategories: mockNaicsCategories,
  naicsCodes: mockNaicsCode,
  currentTaxYear: 2023,
}

export const saveBusinessAccountResponse: SaveBusinessAccountMutation = {
  saveBusinessAccount: {
    id: '35',
    accountNumber: 'P01',
    businessName: 'Rocky Mountain Cycle Plaza - RMCP API Test',
    description: 'Residential Remodeling',
    sicCode: {
      id: '4',
      sicCode: '902',
      sicCodeDescription: 'GOLF COURSE',
      sicSiloCode: '7000',
      subSiloCode: '7700',
      isActive: true,
    },
    areaNumber: '002-0000',
    parcelNumber: '225522',
    ein: '12-3456789',
    registeredEntityName: 'Rocky Mountain Cycle Plaza LLC',
    website: 'https://www.rockymountain.com/',
    yearOpened: 1998,
    isHomeOccupational: false,
    hasAssociatedAccounts: false,
    isActive: true,
    isAccountReadOnly: false,
    applyPenalty: true,
    parcelUseType: {
      id: '2',
      label: 'Commercial',
    },
    accountType: {
      id: '1',
      label: 'Existing Business',
    },
    entityType: {
      id: '1',
      label: 'LLC',
    },
    industrialPark: {
      id: '1',
      code: 'BDO',
      label: 'RE-Business Depot Ogden',
    },
    lastModified: {
      username: '2 - businessowner@example.com',
      lastChangedDate: '2023-06-08T15:40:56',
    },
    reconciliation: {
      reconciledBy: null,
    },
    filingStatus: {
      hasAttachment: false,
      hasBuildingPermit: false,
      hasExemption: false,
      dateReceived: '2023-05-29T15:40:54',
      filingYear: 2023,
      howFiled: {
        id: '2',
        label: 'FiledOnly',
      },
      howReceived: {
        id: '3',
        label: 'PaymentOnly',
      },
    },
    audit: {
      auditYear: 2022,
    },
    businessAppraiserRegion: {
      id: '2',
      groupingValue1: 'Ogden',
      groupingValue2: 'OG',
      regionName: 'Region - 1',
    },
    situsAddress: {
      addressLine1: '1234 NewNew SuperSitus Ave',
      addressLine2: 'Suite 3',
      city: 'Ogden',
      state: State.Ut,
      zipCode: '81530',
    },
    accountSummary: {
      cost: 0,
      taxable: 0,
      taxRate: 0.007424,
      assessmentCharge: 0,
      directCharge: 0,
      penaltyCharge: 0,
      payments: 0,
      currentBalance: 0,
      delinquentBalance: 0,
      totalBalance: 0,
      interest: 0,
    },
  },
}

export const primaryContact: Contact = {
  id: '597',
  email: 'bmarley@gmail.com',
  name: 'Bob Marley',
  phone: '(719) 495-2789',
  phoneExtension: '8116',
  cellphone: '(719) 921-7382',
  isPrimary: true,
  sameAsBusinessAddress: false,
  contactType: {
    id: '2',
    label: 'Owner',
  },
  mailingAddress: {
    inCareOf: null,
    addressLine1: '6123 S State St',
    addressLine2: 'Main Door',
    city: 'Murray',
    state: 'UT',
    zipCode: '84107',
    country: {
      code: 'USA',
      name: 'United States of America',
    },
  },
} as const

export const nonPrimaryContact: Contact = {
  id: '124',
  email: 'fmercury@gmail.com',
  name: 'Freddie Mercury',
  phone: '(720) 496-7512',
  phoneExtension: '4761',
  cellphone: '(720) 922-7844',
  isPrimary: false,
  sameAsBusinessAddress: false,
  contactType: {
    id: '4',
    label: 'Preparer',
  },
  mailingAddress: {
    inCareOf: null,
    addressLine1: null,
    addressLine2: null,
    city: null,
    state: null,
    zipCode: null,
    country: null,
  },
} as const

export const getCreateContactDataResponse: GetCreateContactDataQuery = {
  countries,
  contactTypes,
}

export const contactList = [primaryContact, nonPrimaryContact]

export const userList: UserGraphQl[] = [
  {
    id: 1,
    username: 'admin@example.com',
    email: 'admin@example.com',
    fullName: 'Development ADMIN',
    isActive: true,
    isVerified: true,
  },
  {
    id: 2,
    username: 'admin2@example.com',
    email: 'admin2@example.com',
    fullName: 'ADMIN 2',
    isActive: true,
    isVerified: true,
  },
  {
    id: 3,
    username: 'admin3@example.com',
    email: 'admin3@example.com',
    fullName: 'ADMIN 3',
    isActive: true,
    isVerified: true,
  },
]

export const situsAddress: SitusAddress = {
  addressLine1: '3535 W 3500 S',
  addressLine2: 'Suite #3',
  city: 'West Valley City',
  state: State.Ut,
  zipCode: '84119',
} as const

export const emptyMailingAddress: Address = {
  addressLine1: null,
  addressLine2: null,
  city: null,
  state: null,
  zipCode: null,
  country: null,
}

export const filedStatusList = [
  {
    id: '0',
    label: 'Filed',
  },
  {
    id: '1',
    label: 'Inactive',
  },
  {
    id: '2',
    label: 'In Progress',
  },
  {
    id: '3',
    label: 'Suspended',
  },
  {
    id: '4',
    label: 'Not Filed',
  },
]

export const regeneratePinResponse: RegeneratePinMutation = {
  regeneratePin: 'newsupersecretpin!',
}

export const changeAccountStatusResponse: ChangeBusinessAccountStatusMutation = {
  changeBusinessAccountStatus: true,
}

export const changeLeaseMasterAccountStatusResponse: ChangeLeaseMasterAccountStatusMutation = {
  changeLeaseMasterAccountStatus: true,
}

export const businessAccountMock: BusinessAccount = {
  id: '1',
  accountNumber: '123',
  accountType: {
    id: '1',
    label: 'Account Type',
  },
  areaNumber: '123',
  businessName: 'Business Name',
  audit: {},
  filingStatus: {
    hasAttachment: false,
    hasBuildingPermit: false,
    hasExemption: false,
  },
  hasAssociatedAccounts: false,
  isAccountReadOnly: false,
  isActive: true,
  applyPenalty: false,
  lastModified: {},
  pin: '1234',
  reconciliation: {},
  situsAddress: {
    addressLine1: 'False St 123',
    city: 'Weber',
    state: 'Utah',
    zipCode: '12345',
  },
  primaryContact,
}

export const impersonatedUserToken =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiIxMDQ0IiwiaXNBZG1pbiI6ZmFsc2UsImlzVGVsZWNvbVVzZXIiOmZhbHNlLCJlbWFpbCI6ImJ1c2luZXNzb3duZXJAZXhhbXBsZS5jb20iLCJpbXBlcnNvbmF0b3JJZCI6MTA0NywiaW1wZXJzb25hdG9ySnVyaXNkaWN0aW9uSWQiOjI3LCJleHAiOjI2OTkzNzkyOTYsImlzcyI6Imh0dHBzOi8vbG9jYWxob3N0OjQ0MzAwIiwiYXVkIjoiaHR0cHM6Ly9sb2NhbGhvc3Q6NDQzMDAifQ.Ya085WGgQ8Prww2maR3MpnqXXJUPC3XSo4Ur6bPmZfI'

export const validMasterUserLoginData: MasterLoginMutation = {
  masterLogin: {
    token:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiIxIiwiaXNBZG1pbiI6ZmFsc2UsImlzVGVsZWNvbVVzZXIiOmZhbHNlLCJpc01hc3RlclVzZXIiOnRydWUsImV4cCI6MTc0Mjg3MTg4OCwiaXNzIjoiaHR0cHM6Ly9sb2NhbGhvc3Q6NDQzMDAiLCJhdWQiOiJodHRwczovL2xvY2FsaG9zdDo0NDMwMCJ9.q42q4sl1DfU9d4KH8SwPnmZ55dg0UhppLetkEh1yaxE',
  },
}

export const leaseMasterBusinessAccountMaxFields: LeaseMasterBusinessAccount = {
  id: '729',
  accountNumber: 'LM00001',
  businessName: 'Coca Cola',
  registeredEntityName: 'Coca-Cola Enterprises Inc',
  description: 'Sells beverages for restaurants and supermarkets.',
  sicCode: {
    id: '5711',
    sicCode: '5870',
    sicCodeDescription: 'Sandwich Shop/Beverage/Ice Cream Shop',
    sicSiloCode: '4000',
    subSiloCode: '4250',
    isActive: true,
  },
  ein: '84-6423944',
  applyPenalty: true,
  isActive: true,
  isDisabled: false,
  lastModified: {
    username: '53 - David Nunez BAF',
    lastChanged: '2024-05-22T10:48:14',
  },
  reconciliation: {
    reconciledDate: '2023-12-04T10:57:16',
    reconciledBy: {
      id: 2,
      email: 'janedoe@example.com',
      fullName: 'Jane Doe',
      username: 'janedoe',
      isActive: true,
      isVerified: true,
    },
  },
  filingStatus: {
    hasAttachment: true,
    hasBuildingPermit: true,
    hasExemption: true,
    dateReceived: '2024-05-11T04:27:22',
    howFiled: {
      id: '5',
      label: 'Unknown',
    },
    howReceived: {
      id: '4',
      label: 'None',
    },
  },
  bankruptcy: {
    bankruptcyType: {
      id: '10',
      label: 'Bankruptcy Ch. 7',
    },
  },
  audit: {
    auditYear: 1892,
  },
  entityType: {
    id: '3',
    label: 'Corporation',
  },
  accountType: {
    id: '6',
    label: 'Leasing Account',
  },
  accountSummary: {
    cost: 20820,
    taxable: 3513,
    taxRate: 0.006693,
    assessmentCharge: 23.51,
    directCharge: 0,
    penaltyCharge: 0,
    payments: 0,
    interest: 0,
    currentBalance: 23.51,
    delinquentBalance: 0,
    totalBalance: 23.51,
  },
}

export const leaseMasterBusinessAccountMinFields: LeaseMasterBusinessAccount = {
  id: '729',
  accountNumber: 'LM00001',
  businessName: 'Coca Cola',
  accountType: {
    id: '6',
    label: 'Leasing Account',
  },
  sicCode: {
    id: '5711',
    sicCode: '5870',
    sicCodeDescription: 'Sandwich Shop/Beverage/Ice Cream Shop',
    sicSiloCode: '4000',
    subSiloCode: '4250',
    isActive: true,
  },
  registeredEntityName: null,
  description: null,
  ein: null,
  applyPenalty: true,
  isActive: true,
  isDisabled: false,
  lastModified: {
    username: null,
    lastChanged: null,
  },
  reconciliation: {
    reconciledDate: null,
    reconciledBy: null,
  },
  filingStatus: {
    hasAttachment: false,
    hasBuildingPermit: false,
    hasExemption: false,
    dateReceived: null,
    howFiled: null,
    howReceived: null,
  },
  bankruptcy: {
    bankruptcyType: null,
  },
  audit: {
    auditYear: null,
  },
  entityType: null,
  accountSummary: {
    cost: null,
    taxable: null,
    taxRate: null,
    assessmentCharge: null,
    directCharge: null,
    payments: null,
    interest: null,
    penaltyCharge: null,
    currentBalance: null,
    delinquentBalance: null,
    totalBalance: null,
  },
}

export const leaseMasterBusinessAccountMinFieldsForReconciliation: LeaseMasterBusinessAccount = {
  id: '729',
  accountNumber: 'LM00001',
  businessName: 'Coca Cola',
  registeredEntityName: 'Coca-Cola Enterprises Inc',
  description: 'Sells beverages for restaurants and supermarkets.',
  sicCode: {
    id: '5711',
    sicCode: '5870',
    sicCodeDescription: 'Sandwich Shop/Beverage/Ice Cream Shop',
    sicSiloCode: '4000',
    subSiloCode: '4250',
    isActive: true,
  },
  ein: '84-6423944',
  applyPenalty: true,
  isActive: true,
  isDisabled: false,
  lastModified: {
    username: '53 - David Nunez BAF',
    lastChanged: '2024-05-22T10:48:14',
  },
  reconciliation: {
    reconciledDate: null,
    reconciledBy: null,
  },
  filingStatus: {
    hasAttachment: true,
    hasBuildingPermit: true,
    hasExemption: true,
    dateReceived: '2024-05-11T04:27:22',
    howFiled: {
      id: '5',
      label: 'Unknown',
    },
    howReceived: {
      id: '2',
      label: 'FiledOnly',
    },
  },
  bankruptcy: {
    bankruptcyType: {
      id: '10',
      label: 'Bankruptcy Ch. 7',
    },
  },
  audit: {
    auditYear: 1892,
  },
  entityType: {
    id: '3',
    label: 'Corporation',
  },
  accountType: {
    id: '6',
    label: 'Leasing Account',
  },
  accountSummary: {
    cost: 20820,
    taxable: 3513,
    taxRate: 0.006693,
    assessmentCharge: 23.51,
    directCharge: 0,
    penaltyCharge: 0,
    payments: 0,
    interest: 0,
    currentBalance: 23.51,
    delinquentBalance: 0,
    totalBalance: 23.51,
  },
}

export const leaseMasterAccountNotFound = [
  {
    message: 'Account not found',
    locations: [
      {
        line: 3,
        column: 3,
      },
    ],
    path: ['leaseMasterBusinessAccount'],
    extensions: {
      code: 'INTERNAL_SERVER_ERROR',
    },
  },
]

export const searchLeaseMasterAccountMock = {
  totalAccounts: 34,
  leaseAccounts: [
    {
      accountId: '8',
      accountNumber: 'LM00008',
      businessName: 'Direct tv ',
      isActive: true,
      isReconciled: true,
      filingStatus: 'Not Filed',
      countyName: 'Washington',
    },
    {
      accountId: '10',
      accountNumber: 'LM00010',
      businessName: 'Direct TV 2 LLC',
      isActive: true,
      isReconciled: true,
      filingStatus: 'Not Filed',
      countyName: 'Washington',
    },
    {
      accountId: '1',
      accountNumber: 'LM00001',
      businessName: 'DIRECT TV, LLC C/O KROLL LLC',
      isActive: true,
      isReconciled: false,
      filingStatus: 'In Progress',
      countyName: 'Washington',
    },
    {
      accountId: '2660',
      accountNumber: 'LM00021',
      businessName: 'DIRECT TV, LLC C/O KROLL LLC',
      isActive: true,
      isReconciled: false,
      filingStatus: 'Not Filed',
      countyName: 'Washington',
    },
    {
      accountId: '2661',
      accountNumber: 'LM00022',
      businessName: 'Het Kitchen',
      isActive: true,
      isReconciled: false,
      filingStatus: 'Not Filed',
      countyName: 'Washington',
    },
  ],
}

export const mockSubAccountSummary: SubAccountTaxDistrict[] = [
  {
    accountId: '1',
    accountNumber: 'P043642',
    taxDistrict: '056-0000',
    locationCount: 0,
    totalAssetCount: 0,
    isExempt: false,
    isActive: true,
    bafInActiveAccountsCount: 0,
    invalidTaxDistrictCount: 0,
    jurisdictionId: 27,
    leaseMasterId: 123,
    recordType: 'E',
    taxYear: 2023,
    accountSummary: {
      cost: 0,
      taxable: 0,
      taxRate: 0.007574,
      assessmentCharge: 0,
      directCharge: 0,
      penaltyCharge: 0,
      payments: 0,
      interest: 0,
      currentBalance: 0,
      delinquentBalance: 0,
      totalBalance: 0,
    },
  },
  {
    accountId: '2',
    accountNumber: 'P043643',
    taxDistrict: '057-0000',
    locationCount: 1,
    totalAssetCount: 1,
    isExempt: true,
    isActive: false,
    bafInActiveAccountsCount: 0,
    invalidTaxDistrictCount: 0,
    jurisdictionId: 27,
    leaseMasterId: 123,
    recordType: 'E',
    taxYear: 2023,
    accountSummary: {
      cost: 100,
      taxable: 50,
      taxRate: 0.007574,
      assessmentCharge: 10,
      directCharge: 5,
      penaltyCharge: 2,
      payments: 20,
      interest: 1,
      currentBalance: 30,
      delinquentBalance: 10,
      totalBalance: 40,
    },
  },
]

export const subAccountMock: SubAccountTaxDistrict = {
  accountId: '111607',
  accountNumber: 'P0111607',
  jurisdictionId: 27,
  leaseMasterId: 2673,
  taxYear: 2024,
  recordType: 'E',
  taxDistrict: '013-0001',
  locationCount: 0,
  totalAssetCount: 0,
  bafInActiveAccountsCount: 0,
  invalidTaxDistrictCount: 0,
  isExempt: false,
  isActive: true,
  accountSummary: {
    cost: 0,
    taxable: 0,
    taxRate: 0.006663,
    assessmentCharge: 0,
    directCharge: 0,
    penaltyCharge: 0,
    payments: 0,
    interest: 0,
    currentBalance: 0,
    delinquentBalance: 0,
    totalBalance: 0,
  },
}

export const taxDistrictBusinessAccountFoodStopInc: TaxDistrictBusinessAccountRow = {
  jurisdictionId: 27,
  leaseMasterId: '729',
  subAccountId: '111607',
  businessAccountId: '134023',
  isHoldingAccount: false,
  accountNumber: '134023',
  businessName: 'KEVIN FOOD STOP INC',
  bafIsActive: true,
  invalidTaxDistrict: false,
  address: {
    contactId: '603596',
    addressLine1: '5190 S 1900 W',
    addressLine2: null,
    city: 'ROY',
    state: 'UT',
    zipCode: '84067',
  },
  isActive: true,
}
export const taxDistrictBusinessAccountInactive: TaxDistrictBusinessAccountRow = {
  jurisdictionId: 27,
  leaseMasterId: '729',
  subAccountId: '111607',
  businessAccountId: '134023',
  isHoldingAccount: false,
  accountNumber: '134023',
  businessName: 'KEVIN FOOD STOP INC',
  bafIsActive: true,
  invalidTaxDistrict: false,
  address: {
    contactId: '603596',
    addressLine1: '5190 S 1900 W',
    addressLine2: null,
    city: 'ROY',
    state: 'UT',
    zipCode: '84067',
  },
  isActive: false,
}

export const taxDistrictHoldingAccount: TaxDistrictBusinessAccountRow = {
  jurisdictionId: 27,
  leaseMasterId: '729',
  subAccountId: '111607',
  businessAccountId: '109167',
  isHoldingAccount: true,
  accountNumber: '109167',
  businessName: 'Billboard',
  bafIsActive: true,
  invalidTaxDistrict: false,
  address: {
    contactId: '595730',
    addressLine1: '935 W Binford St',
    addressLine2: null,
    city: 'Odgen',
    state: 'UT' as State,
    zipCode: '84401',
  },
  isActive: true,
}

export const taxDisctrictBusinessAccountDennys: TaxDistrictBusinessAccountRow = {
  jurisdictionId: 27,
  leaseMasterId: '729',
  subAccountId: '111607',
  businessAccountId: '1646',
  isHoldingAccount: false,
  accountNumber: 'P001646',
  businessName: 'DENNYS RESTAURANT #6260',
  bafIsActive: true,
  invalidTaxDistrict: false,
  address: {
    contactId: '584734',
    addressLine1: '1215 S MAIN ST',
    addressLine2: null,
    city: 'SAINT GEORGE',
    state: 'UT',
    zipCode: '84770',
  },
  isActive: true,
}

export const taxDistrictBusinessAccountList = [taxDistrictBusinessAccountFoodStopInc]

export const taxDistrictBusinessAccountListWithHoldingAccount = [
  taxDistrictHoldingAccount,
  taxDistrictBusinessAccountFoodStopInc,
]

export const getRolesResult = {
  getRoles: [
    {
      id: '1',
      name: 'Test Role 1',
      description: 'First Description',
      permissions: [
        {
          id: '1',
          name: 'Operation1',
        },
        {
          id: '2',
          name: 'Operation2',
        },
        {
          id: '3',
          name: 'Operation3',
        },
      ],
    },
    {
      id: '2',
      name: 'Test Role 2',
      description: 'Second Description',
      permissions: [
        {
          id: '1',
          name: 'Operation1',
        },
        {
          id: '3',
          name: 'Operation3',
        },
      ],
    },
    {
      id: '3',
      name: 'Test Role 3',
      description: 'Third Description',
      permissions: [
        {
          id: '1',
          name: 'Operation1',
        },
      ],
    },
  ],
}

export const getPermissionsResult = {
  getPermissions: [
    {
      id: '1',
      name: 'Operation1',
    },
    {
      id: '2',
      name: 'Operation2',
    },
    {
      id: '3',
      name: 'Operation3',
    },
  ],
}

export const saveUpdateRoleError = [
  {
    message: 'Role error',
  },
]

export const getPostingPaymentDataResponse: PostingPayment[] = [
  {
    id: 1,
    receiptNumber: 1,
    paymentType: 1,
    paymentAmount: 100,
    leaseMasterAccountNbr: '000001',
    receivedFrom: 'DIRECT TV, LLC C/O KROLL LLC',
  },
  {
    id: 2,
    receiptNumber: 2,
    paymentType: 1,
    paymentAmount: 150,
    leaseMasterAccountNbr: '000002',
    receivedFrom: 'DIRECT TV, LLC C/O KROLL LLC',
  },
  {
    id: 3,
    receiptNumber: 3,
    paymentType: 1,
    paymentAmount: 99.03,
    leaseMasterAccountNbr: null,
    receivedFrom: 'Direct tv, Test4',
  },
]

export const savePostingPaymentResponse = [
  {
    id: 1,
    receiptNumber: 1,
    leaseMasterId: 2,
    paymentType: 1,
    paymentAmount: 100,
    leaseMasterAccountNbr: null,
    receivedFrom: 'DIRECT TV, LLC C/O KROLL LLC',
  },
  {
    id: 2,
    receiptNumber: 2,
    leaseMasterId: 2,
    paymentType: 1,
    paymentAmount: 150,
    leaseMasterAccountNbr: null,
    receivedFrom: 'DIRECT TV, LLC C/O KROLL LLC',
  },
  {
    id: 3,
    receiptNumber: 3,
    leaseMasterId: 2,
    paymentType: 1,
    paymentAmount: 99.03,
    leaseMasterAccountNbr: null,
    receivedFrom: 'Direct tv, Test4',
  },
  {
    id: 4,
    receiptNumber: 4,
    leaseMasterId: 2,
    paymentType: 1,
    paymentAmount: 300,
    leaseMasterAccountNbr: null,
    receivedFrom: 'Direct tv, new',
  },
]

export function generateRandomReceiptData(numObjects = 50): PostingPayment[] {
  const paymentTypes = [1, 2, 3, 4] // Corresponding to Check, Cash, Credit Card, ECheck
  const receipts = []

  for (let i = 1; i <= numObjects; i++) {
    const randomPaymentType = paymentTypes[Math.floor(Math.random() * paymentTypes.length)]

    const receipt = {
      id: i,
      receiptNumber: i,
      leaseMasterId: Math.floor(Math.random() * 10) + 1,
      paymentType: randomPaymentType,
      paymentAmount: Math.floor(Math.random() * 500) + 50,
      leaseMasterAccountNbr: `LM${Math.floor(Math.random() * 100000)
        .toString()
        .padStart(5, '0')}`,
      receivedFrom: `Company ${Math.floor(Math.random() * 100)}`,
    }

    receipts.push(receipt)
  }

  return receipts
}

export const getPaymentTypesResponse = [
  {
    id: 1,
    description: 'Check',
  },
  {
    id: 2,
    description: 'Cash',
  },
  {
    id: 3,
    description: 'Credit Card',
  },
  {
    id: 4,
    description: 'ECheck',
  },
]

export const createSubAccountTaxDistrictResponse: CreateSubAccountTaxDistrictMutation = {
  createSubAccountTaxDistrict: {
    accountId: '43667',
    accountNumber: 'P043667',
    taxDistrict: '003-0001',
    locationCount: 0,
    totalAssetCount: 0,
    isExempt: false,
    isActive: true,
    accountSummary: {
      cost: 0,
      taxable: 0,
      taxRate: 0.007455,
      assessmentCharge: 0,
      directCharge: 0,
      penaltyCharge: 0,
      payments: 0,
      interest: 0,
      currentBalance: 0,
      delinquentBalance: 0,
      totalBalance: 0,
      totalBalanceWithDelinquent: null,
    },
  },
}

export const mockPrimaryContacts = [
  {
    id: '1',
    name: 'John Doe',
    email: 'john@example.com',
    phone: '123-456-7890',
    mailingAddress: {
      addressLine1: '123 Main St',
      city: 'Anytown',
      state: 'CA',
      zipCode: '12345',
    },
  },
  {
    id: '2',
    name: 'Jane Smith',
    email: 'jane@example.com',
    cellphone: '987-654-3210',
    mailingAddress: null,
  },
]
